import { default as adminszBQwS932w5Meta } from "/tmp/build_b93f3ddf/pages/administration/admins.vue?macro=true";
import { default as _91id_93ckFx2sE4tHMeta } from "/tmp/build_b93f3ddf/pages/administration/billing/[id].vue?macro=true";
import { default as index6b1trsRjHrMeta } from "/tmp/build_b93f3ddf/pages/administration/billing/index.vue?macro=true";
import { default as bookingsNZTJP71kMGMeta } from "/tmp/build_b93f3ddf/pages/administration/bookings.vue?macro=true";
import { default as exportstCpVP8cTkhMeta } from "/tmp/build_b93f3ddf/pages/administration/exports.vue?macro=true";
import { default as fidelityHDFAmpx7ZNMeta } from "/tmp/build_b93f3ddf/pages/administration/fidelity.vue?macro=true";
import { default as invoicesPiasPI6vnrMeta } from "/tmp/build_b93f3ddf/pages/administration/invoices.vue?macro=true";
import { default as policyNt0rqqmj4uMeta } from "/tmp/build_b93f3ddf/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_9324XaM9IWkCMeta } from "/tmp/build_b93f3ddf/pages/administration/payment/[id].vue?macro=true";
import { default as indexEMKhyNpy3hMeta } from "/tmp/build_b93f3ddf/pages/administration/payment/index.vue?macro=true";
import { default as co2CjrJrxOuMnMeta } from "/tmp/build_b93f3ddf/pages/administration/statistics/co2.vue?macro=true";
import { default as indexkC0VNVLTbZMeta } from "/tmp/build_b93f3ddf/pages/administration/statistics/index.vue?macro=true";
import { default as indexUtqBeVUABtMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationHNc8qB6yWwMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexpIyzkPpHJSMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyipOTA2kMrXMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsjoeNS3NeOOMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93BpPnuQeimIMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexf9twfgBgGSMeta } from "/tmp/build_b93f3ddf/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelssRxoXypjtfMeta } from "/tmp/build_b93f3ddf/pages/administration/trip-labels.vue?macro=true";
import { default as administrationoAAKN14erPMeta } from "/tmp/build_b93f3ddf/pages/administration.vue?macro=true";
import { default as swile4KfsHszOCbMeta } from "/tmp/build_b93f3ddf/pages/auth/swile.vue?macro=true";
import { default as indexYrFsAA4hq6Meta } from "/tmp/build_b93f3ddf/pages/bookings/index.vue?macro=true";
import { default as indexb9Ol8WURQcMeta } from "/tmp/build_b93f3ddf/pages/bookings/validator/index.vue?macro=true";
import { default as pendingO3NgQ0kcoXMeta } from "/tmp/build_b93f3ddf/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93YLPTz65jCUMeta } from "/tmp/build_b93f3ddf/pages/car/[id].vue?macro=true";
import { default as fidelity50WLWtTc2uMeta } from "/tmp/build_b93f3ddf/pages/fidelity.vue?macro=true";
import { default as exchangeIRkDjyqIgeMeta } from "/tmp/build_b93f3ddf/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresuqGsuzRrp4Meta } from "/tmp/build_b93f3ddf/pages/flight/[id]/fares.vue?macro=true";
import { default as indexixYOrofjZiMeta } from "/tmp/build_b93f3ddf/pages/flight/[id]/index.vue?macro=true";
import { default as forgotFuX40ki619Meta } from "/tmp/build_b93f3ddf/pages/forgot.vue?macro=true";
import { default as get_45swile_45appJkM8VVdK2vMeta } from "/tmp/build_b93f3ddf/pages/get-swile-app.vue?macro=true";
import { default as helpNELmn9rQ8kMeta } from "/tmp/build_b93f3ddf/pages/help.vue?macro=true";
import { default as _91id_93PcdYVyQQCqMeta } from "/tmp/build_b93f3ddf/pages/hotel/[id].vue?macro=true";
import { default as _91id_93qXAxKad7RUMeta } from "/tmp/build_b93f3ddf/pages/impersonate/[id].vue?macro=true";
import { default as indexjOrn1NZirJMeta } from "/tmp/build_b93f3ddf/pages/index.vue?macro=true";
import { default as _91suffix_93W73ikiqHxBMeta } from "/tmp/build_b93f3ddf/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_930JZo3LNq7EMeta } from "/tmp/build_b93f3ddf/pages/invite/[token].vue?macro=true";
import { default as _91id_93Cwpd1Fz0qrMeta } from "/tmp/build_b93f3ddf/pages/journeys/[id].vue?macro=true";
import { default as indexE0R7DSzFtCMeta } from "/tmp/build_b93f3ddf/pages/journeys/index.vue?macro=true";
import { default as members7QZDysac8qMeta } from "/tmp/build_b93f3ddf/pages/myteam/members.vue?macro=true";
import { default as policyBJE8KOFDQdMeta } from "/tmp/build_b93f3ddf/pages/myteam/policy.vue?macro=true";
import { default as validatorsXJbJV2uqxuMeta } from "/tmp/build_b93f3ddf/pages/myteam/validators.vue?macro=true";
import { default as myteamgENUApH9AqMeta } from "/tmp/build_b93f3ddf/pages/myteam.vue?macro=true";
import { default as new_45uservxgL5vIupbMeta } from "/tmp/build_b93f3ddf/pages/new-user.vue?macro=true";
import { default as passwordDtzh3TWbOGMeta } from "/tmp/build_b93f3ddf/pages/password.vue?macro=true";
import { default as index5a5ZyvKTLZMeta } from "/tmp/build_b93f3ddf/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexVkIHtNCbJRMeta } from "/tmp/build_b93f3ddf/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93KIoA5k3j4jMeta } from "/tmp/build_b93f3ddf/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_930ykUeWhD2NMeta } from "/tmp/build_b93f3ddf/pages/search/flight/[search_id].vue?macro=true";
import { default as indexjDIGX5zdcFMeta } from "/tmp/build_b93f3ddf/pages/search/flight/index.vue?macro=true";
import { default as indexkTR00bcCPpMeta } from "/tmp/build_b93f3ddf/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93PMXq1zyplZMeta } from "/tmp/build_b93f3ddf/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as index1VhwAW6jdSMeta } from "/tmp/build_b93f3ddf/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93iKJBo7PSZdMeta } from "/tmp/build_b93f3ddf/pages/search/train/[search_id].vue?macro=true";
import { default as indexycLWnEhv5aMeta } from "/tmp/build_b93f3ddf/pages/search/train/index.vue?macro=true";
import { default as searchx2hXUyez46Meta } from "/tmp/build_b93f3ddf/pages/search.vue?macro=true";
import { default as index3lqFjSTlEXMeta } from "/tmp/build_b93f3ddf/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationRLZskIeujRMeta } from "/tmp/build_b93f3ddf/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policy8e8nuXJYQuMeta } from "/tmp/build_b93f3ddf/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validators4DXW9Jtl5kMeta } from "/tmp/build_b93f3ddf/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_933FIiYFjT3ZMeta } from "/tmp/build_b93f3ddf/pages/teams/[teamId].vue?macro=true";
import { default as indexRWXA7ydx1mMeta } from "/tmp/build_b93f3ddf/pages/teams/index.vue?macro=true";
import { default as teamsz5vh1g8O97Meta } from "/tmp/build_b93f3ddf/pages/teams.vue?macro=true";
import { default as indexVoDjP0C67iMeta } from "/tmp/build_b93f3ddf/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexSx6j1BEDaHMeta } from "/tmp/build_b93f3ddf/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexNq109Owyt0Meta } from "/tmp/build_b93f3ddf/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexzryFJIQEHEMeta } from "/tmp/build_b93f3ddf/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexCrurMeMTmOMeta } from "/tmp/build_b93f3ddf/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseZcF6VoVeBoMeta } from "/tmp/build_b93f3ddf/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitygfTvS5ANEFMeta } from "/tmp/build_b93f3ddf/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexoi8KYvcPKpMeta } from "/tmp/build_b93f3ddf/pages/users/[id]/index.vue?macro=true";
import { default as passportHdC6Pu7brOMeta } from "/tmp/build_b93f3ddf/pages/users/[id]/passport.vue?macro=true";
import { default as passwordT41JUTIrQrMeta } from "/tmp/build_b93f3ddf/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93M9TmZvRkPDMeta } from "/tmp/build_b93f3ddf/pages/users/[id].vue?macro=true";
import { default as indexgVINYAn4cQMeta } from "/tmp/build_b93f3ddf/pages/users/index.vue?macro=true";
import { default as vouchersRUqBN515VoMeta } from "/tmp/build_b93f3ddf/pages/vouchers.vue?macro=true";
import { default as component_45stubz0nCSu05tfMeta } from "/tmp/build_b93f3ddf/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubz0nCSu05tf } from "/tmp/build_b93f3ddf/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationoAAKN14erPMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/administration.vue"),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/admins.vue")
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/billing/[id].vue")
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/billing/index.vue")
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/bookings.vue")
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/exports.vue")
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/fidelity.vue")
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/invoices.vue")
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/organization/policy.vue")
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/payment/[id].vue")
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/payment/index.vue")
  },
  {
    name: "administration-statistics-co2",
    path: "statistics/co2",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/statistics/co2.vue")
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/statistics/index.vue")
  },
  {
    name: _91teamId_93BpPnuQeimIMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId].vue"),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexUtqBeVUABtMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/index.vue")
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/invitation.vue")
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/policy/index.vue")
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyipOTA2kMrXMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/policy/modify.vue")
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/teams/index.vue")
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_b93f3ddf/pages/administration/trip-labels.vue")
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swile4KfsHszOCbMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/auth/swile.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_b93f3ddf/pages/bookings/index.vue")
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexb9Ol8WURQcMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/bookings/validator/index.vue")
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingO3NgQ0kcoXMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/bookings/validator/pending.vue")
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/car/[id].vue")
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_b93f3ddf/pages/fidelity.vue")
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_b93f3ddf/pages/flight/[id]/exchange.vue")
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_b93f3ddf/pages/flight/[id]/fares.vue")
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/flight/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotFuX40ki619Meta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/forgot.vue")
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appJkM8VVdK2vMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/get-swile-app.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_b93f3ddf/pages/help.vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/hotel/[id].vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/impersonate/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjOrn1NZirJMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/index.vue")
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93W73ikiqHxBMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/invitation/[suffix].vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_930JZo3LNq7EMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/invite/[token].vue")
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/journeys/[id].vue")
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_b93f3ddf/pages/journeys/index.vue")
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_b93f3ddf/pages/myteam.vue"),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_b93f3ddf/pages/myteam/members.vue")
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_b93f3ddf/pages/myteam/policy.vue")
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_b93f3ddf/pages/myteam/validators.vue")
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45uservxgL5vIupbMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/new-user.vue")
  },
  {
    name: "password",
    path: "/password",
    meta: passwordDtzh3TWbOGMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_b93f3ddf/pages/search.vue"),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/car/[search_id]/index.vue")
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_b93f3ddf/pages/search/car/index.vue")
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/car/init/[search_id].vue")
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/flight/[search_id].vue")
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_b93f3ddf/pages/search/flight/index.vue")
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/hotel/[search_id]/index.vue")
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/hotel/[search_id]/rooms/[hotelIds].vue")
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_b93f3ddf/pages/search/hotel/index.vue")
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_b93f3ddf/pages/search/train/[search_id].vue")
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_b93f3ddf/pages/search/train/index.vue")
  }
]
  },
  {
    name: teamsz5vh1g8O97Meta?.name,
    path: "/teams",
    meta: teamsz5vh1g8O97Meta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/teams.vue"),
    children: [
  {
    name: _91teamId_933FIiYFjT3ZMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/[teamId]/invitation.vue")
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/[teamId]/policy.vue")
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_b93f3ddf/pages/teams/index.vue")
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_b93f3ddf/pages/train/[id]/cancel/index.vue")
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_b93f3ddf/pages/train/[id]/exchange/changeable-selection/index.vue")
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_b93f3ddf/pages/train/[id]/exchange/section-selection/index.vue")
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_b93f3ddf/pages/train/[id]/exchange/train-selection/index.vue")
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_b93f3ddf/pages/train/[id]/index.vue")
  },
  {
    name: _91id_93M9TmZvRkPDMeta?.name,
    path: "/users/:id()",
    meta: _91id_93M9TmZvRkPDMeta || {},
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id]/driver-license.vue")
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id]/fidelity.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id]/passport.vue")
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_b93f3ddf/pages/users/[id]/password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_b93f3ddf/pages/users/index.vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_b93f3ddf/pages/vouchers.vue")
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_b93f3ddf/pages/vouchers.vue")
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/metrics",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/en",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/fr",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/pt-BR",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubz0nCSu05tf
  },
  {
    name: component_45stubz0nCSu05tfMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubz0nCSu05tf
  }
]